// Import any other modules or utilities you need
// import { someUtility } from './utilities';
import Auth from './auth';

interface HeyGGConfig {
  apiBaseUrl: string;
}

const defaultConfig: HeyGGConfig = {
  apiBaseUrl: 'https://id.hey.gg'
};

const config = { ...defaultConfig };

const setConfig = (newConfig: Partial<HeyGGConfig>) => {
  window.HeyGoodGame.config = { ...window.HeyGoodGame.config, ...newConfig };
}


  // Export everything you want to make available globally
  export {
    Auth,
    config,
    setConfig,
  };
  
  declare global {
    interface Window {
      HeyGoodGame: {
        Auth: typeof Auth;
        config: HeyGGConfig;
        setConfig: (newConfig: Partial<HeyGGConfig>) => void;
      };
    }
  }

window.HeyGoodGame = {
  Auth,
  config,
  setConfig,
};